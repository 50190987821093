.hero{
    width: 100%;
    height: 100vh;
    position: relative;

}
.hero-mid{
    width: 100%;
    height: 70vh;
    position: relative;
}


.hero-mid h1{
    padding-top: 9rem !important;
}

.hero-mid img{
    width: 100%;
    height:  512px ;
    object-fit: cover ;
}
img{
    width: 1349px;
    height:  480px ;
    object-fit: cover ;
}
.hero-text{
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 469px;
}
.hero-text h1{
    font-weight: 800;
    font-size: 3rem;
    color: white;
}
.hero-text p{
font-weight: 1.3rem;
font-size: 4rem;
font-weight: 600;
color: black;
padding: 0.1rem 0.2rem 0;  
}

.hero-text .show{
text-decoration: none;
background-color: white;
padding: 1rem 2rem;
border-radius: 6px;
font-size: 1.1rem;
font-weight:bold ;
color: black;
}
.hero-text .hide{
    display: none;
}

