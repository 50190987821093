.about-container{
    margin: 4rem 6rem;
    text-align: center;
}
.about-container h1{
    padding-bottom: 1rem;

}

.about-container p{
    padding-bottom: 2rem;
}