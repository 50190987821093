.form-container{
    margin: 4rem 6rem;
    color: black;

}
.form-container form{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}
.form-container input{
    height: 3rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: .3rem;
    border: 1px solid #2a2a2a;
}
.form-container textarea{
    height: 3rem;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: .3rem;
    border: 1px solid #2a2a2a;
}
.form-container button{
    background-color: black; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 250px;
    


}
.btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn:hover{
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
