.trip{
    margin: 4rem 6rem;
    color: #2a2a2a;
}

.trip h1{
    font-size: 3rem;
}

.tripcard{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;

}
.trip-data{
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
}
.t-image{
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
}
.t-image img{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
   
}

.t-image:hover img{
    transform: scale(1.3);
}

.trip-data h3{
    font-size: 1.3rem;
    padding: 0.9rem 0.2rem 0;
    display: flex;
    justify-content: center;
}
.primaryText {
    color: #1f3e72;
    font-weight: bold;
    font-size: 2rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  
  .secondaryText {
    color: rgb(46, 155, 155);
    font-size:3.5rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .orangeText {
    color: cornflowerblue;
    font-size: 1rem;
    font-weight: 600;
    
  }
